import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-hjp-confirm-modal" }
const _hoisted_2 = { class: "header flex" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "container-img" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "sub-title" }
const _hoisted_8 = { class: "container-btn-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ButtonSecondary = _resolveComponent("ButtonSecondary")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dialog, {
      showHeader: false,
      visible: _ctx.showModal,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showModal) = $event)),
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      style: { width: '30vw' },
      modal: true,
      contentClass: "wrap-content-hjp-confirm-modal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("i", {
            class: "pi pi-times cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDialog()))
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", { src: _ctx.imgContent }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.subTitle), 1),
          (_ctx.slot['additionalContent'])
            ? _renderSlot(_ctx.$slots, "additionalContent", { key: 0 })
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.showSubmit)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  type: "submit",
                  class: "btn-size-md mb-2",
                  buttonText: _ctx.btnSubmitTitle,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
                }, null, 8, ["buttonText"]))
              : _createCommentVNode("", true),
            (_ctx.showBtnBack)
              ? (_openBlock(), _createBlock(_component_ButtonSecondary, {
                  key: 1,
                  class: "btn-size-md mr-2",
                  buttonText: _ctx.btnBackTitle,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog()))
                }, null, 8, ["buttonText"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 3
    }, 8, ["visible"])
  ]))
}