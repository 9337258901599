import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  createWebHistory
} from 'vue-router'
import moment from 'moment'
import environment from '@/configs/environtment'
import Main from '@/views/layouts/Main.vue'
import DataTable from '@/views/pages/coba/UsingTable.vue'
import RouteTable from '@/views/pages/master-setting/route/List.vue'
import RouteForm from '@/views/pages/master-setting/route/Form.vue'
import Login from '@/views/pages/auth/Login.vue'
import LoginHjex from '@/views/pages/auth/LoginHjex.vue'
import GlobalSetting from '@/views/pages/global-setting/GlobalSettings.vue'
import Reports from '@/views/pages/reports/index.vue'
import ReportsDetail from '@/views/pages/reports/detail/DetailReport.vue'
import ReportsAdvance from '@/views/pages/reports/detail/ListReportAdvance.vue'

import Contract from '@/views/pages/contract/Index.vue'
import ContractDetail from '@/views/pages/contract/Detail.vue'

import TermsOfPayment from '@/views/pages/terms-of-payment/Index.vue'
import BillDetail from '@/views/pages/terms-of-payment/detail/BillDetail.vue'
import TermsOfPaymentHistory from '@/views/pages/terms-of-payment/History.vue'
import TermsOfPaymentHistoryDetail from '@/views/pages/terms-of-payment/detail/HistoryDetail.vue'

import Transaction from '@/views/pages/transaction/Index.vue'
import TransactionDetail from '@/views/pages/transaction/Detail.vue'

import AccountSettings from '@/views/pages/setting/account/Index.vue'

import ApiUsage from '@/views/pages/dev-settings/api-usage/Index.vue'
import Faq from '@/views/pages/dev-settings/faq/Index.vue'

import store from '../store'

const baseRoute = '/cms'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    // eslint-disable-next-line consistent-return
    beforeEnter: async () => '/login',
    // beforeEnter: async () => {
    //   const ut = localStorage.getItem('ut')
    //   if (ut) {
    //     return `${baseRoute}/`
    //     // eslint-disable-next-line no-else-return
    //   } else {
    //     console.log('environtment', environment.getHydraApi())
    //     // return 'hallo'
    //     window.location.href = `${environment.getHydraApi()}oauth2/auth?client_id=${environment.getHydraClientId()}&scope=${environment.getHydraScope()}&response_type=${environment.getHydraResponseType()}&state=${environment.getHydraState()}&redirect_uri=${window.location.origin}/callback&login_uri=${window.location.origin}/login`
    //   }
    // },
    component: () => import('@/views/pages/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      // this meta property is used to define that the route can acces when user don't have token access
      hideForAuth: true
    }
  },
  {
    path: '/login-hjex',
    name: 'Login-hjex',
    component: LoginHjex,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/pages/auth/ForgotPassword.vue'),
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/pages/auth/ChangePassword.vue'),
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/pages/auth/Callback.vue'),
    // meta: {
    //   // hideForAuth: true
    //   requiresAuth: true
    // }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/pages/privacy-policy/privacy-policy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('@/views/pages/privacy-policy/terms-condition.vue')
  },
  {
    path: '/components',
    name: 'components-example',
    component: () => import('@/views/pages/coba/components.vue')
  },
  {
    path: `${baseRoute}`,
    redirect: `${baseRoute}/${localStorage.getItem('ut') === null ? '' : 'dashboard'}`,
    name: 'Main',
    component: Main,
    meta: {
      // this meta property is used to define that the route can acces when user have token access
      requiresAuth: false
    },
    children: [
      {
        path: 'table',
        name: 'Table',
        component: DataTable,
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/index.vue'),
        meta: {
          group: 'Dashboard',
          title: 'Dashboard',
        }
      },
      {
        path: 'contract',
        name: 'contract',
        component: Contract,
        meta: {
          group: 'Kontrak',
          title: '',
        }
      },
      {
        path: 'contract/detail/:id',
        name: 'contract-detail',
        component: ContractDetail,
        meta: {
          group: 'Kontrak',
          title: '',
        }
      },
      {
        path: 'terms-of-payment',
        name: 'terms-of-payment',
        component: TermsOfPayment,
        meta: {
          group: 'Tagihan',
          title: 'Terms of Payment',
        }
      },
      {
        path: 'terms-of-payment/detail/:id?',
        name: 'terms-of-payment-detail',
        component: BillDetail,
        meta: {
          group: 'Tagihan',
          title: 'Detail Tagihan',
        }
      },
      {
        path: 'terms-of-payment/history',
        name: 'terms-of-payment-history',
        component: TermsOfPaymentHistory,
        meta: {
          group: 'Tagihan',
          title: 'Riwayat Tagihan',
        }
      },
      {
        path: 'terms-of-payment/history-detail/:id?',
        name: 'terms-of-payment-history-detail',
        component: TermsOfPaymentHistoryDetail,
        meta: {
          group: 'Tagihan',
          title: 'Detail Riwayat Tagihan',
        }
      },
      {
        path: 'setting-email-notification',
        name: 'email-notification',
        component: () => import('@/views/pages/setting/email-notification/index.vue'),
        meta: {
          group: 'Pengaturan',
          title: 'Email Notification'
        }
      },
      {
        path: 'setting-access-keys',
        name: 'access-keys',
        component: () => import('@/views/pages/setting/key-access/index.vue'),
        meta: {
          group: 'Pengaturan',
          title: 'Access Keys'
        }
      },
      {
        path: 'setting-account',
        name: 'setting-account',
        component: AccountSettings,
        meta: {
          group: 'Pengaturan',
          title: 'Akun'
        }
      },
      {
        path: 'master-setting-bank',
        name: 'master-bank',
        component: () => import('@/views/pages/master-setting/bank/Table.vue'),
        meta: {
          group: 'MASTER',
          title: 'Master Bank'
        }
      },
      {
        path: 'master-route',
        name: 'master-route',
        component: RouteTable,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Rute'
        }
      },
      {
        path: 'master-route/form/:id?',
        name: 'master-route-form',
        component: RouteForm,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Rute'
        }
      },
      {
        path: 'global-settings',
        name: 'global-settings',
        component: GlobalSetting,
        meta: {
          group: 'SETTINGS',
          title: 'Global Settings'
        }
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
        meta: {
          group: 'DASHBOARD',
          title: 'Laporan'
        }
      },
      {
        path: 'reports-advance',
        name: 'reports-advance',
        component: ReportsAdvance,
        meta: {
          group: 'DASHBOARD',
          title: 'Laporan'
        }
      },
      {
        path: 'contract',
        name: 'contract',
        component: Contract,
        meta: {
          group: 'Kontrak',
          title: '',
        }
      },
      {
        path: 'transaction',
        name: 'transaction',
        component: Transaction,
        meta: {
          group: 'Transaksi',
          title: 'Transaksi'
        }
      },
      {
        path: 'transaction-detail/:id?',
        name: 'transaction-detail',
        component: TransactionDetail,
        meta: {
          group: 'Transaksi',
          title: 'Detail Paket'
        }
      },
      {
        path: 'api-usage',
        name: 'api-usage',
        component: ApiUsage,
        meta: {
          group: 'Developer Setting',
          title: 'Penggunaan API'
        }
      },
      {
        path: 'faq',
        name: 'faq',
        component: Faq,
        meta: {
          group: 'Developer Setting',
          title: 'FAQ'
        }
      }
    ]
  },
  {
    path: '/reports/detail/:typeReport',
    name: 'reports-detail',
    component: ReportsDetail,
    meta: {
      group: 'DASHBOARD',
      title: 'Laporan'
    }
  },
  {
    path: '/xxx',
    name: 'xxx',
    component: () => import('@/views/shared-components/xxx.vue'),
    meta: {
      group: 'DASHBOARD',
      title: 'Laporan'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const checkDateExpired = (): boolean => {
  const token = localStorage.getItem('ut')
  const timeExpired = moment(localStorage.getItem('ei'))
  if (token && timeExpired) {
    const now = moment()
    const checkSeconds = moment.duration(now.diff(timeExpired)).asSeconds()
    if (checkSeconds < 0) {
      return true
    }
  }
  store.dispatch('logoutAccount')

  return false
}

router.beforeEach((to, from, next) => {
  store.dispatch('hideLoading')
  const token = localStorage.getItem('ut')
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (checkDateExpired()) {
      if (token) {
        next()
      } else {
        next({ name: 'Home' })
      }
    } else {
      next({ name: 'Home' })
    }
  } else if (to.matched.some((record) => record.meta.hideForAuth)) {
    // if the user have token then he can access
    if (checkDateExpired()) {
      if (token) {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
